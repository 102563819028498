.home-container {
  display: flex;
  flex-direction: column;

  .header-divider {
    grid-column-start: 1;
    grid-column-end: span 3;
  }

  .home-hero-container {
    background-image: url(/hero_background.jpg);
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
  }

  .home-content-container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 30px;
    align-self: center;
    max-width: 1260px;
    margin: 0 16px 30px 16px;

    .home-content {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    .home-content-articles {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;

      grid-column-start: 1;
      // grid-column-end: 3;
      grid-row-start: 3;
      // grid-row-end: 5;
    }

    .updates-outer-container {
      grid-row-start: 3;
      grid-row-end: 5;
    }
  }

  @media (max-width: 1040px) {
    .header-divider {
      grid-column-end: auto;
    }

    .home-content-container {
      display: inline;

      .home-content-articles {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-gap: 30px;

        grid-column-start: auto;
        grid-column-end: auto;
        grid-row-start: auto;
        grid-row-end: auto;
      }

      .updates-outer-container {
        grid-row-start: 5;
        grid-row-end: auto;
      }
    }
  }
}
