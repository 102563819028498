.country {
  display: flex;
  max-width: 1260px;
  margin-bottom: -46px;

  .country-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    margin-bottom: 30px;

    .country-map {
      display: flex;
      flex-direction: column;
      grid-column-start: 1;
      grid-column-end: 4;
      margin-bottom: 30px;

      .country-content-right {
        display: flex;
      }
    }

    @media (max-width: 768px) {
      .country-map {
        grid-column-start: 1;
        grid-column-end: 4;
      }

      .country-content-right {
        display: none;
      }
    }
  }

  .country-footer {
    grid-column-end: span 3;
    display: flex;
    flex-direction: column;
    margin-top: -16px;

    > div {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 768px) {
    .country-content {
      grid-template-areas:
        'left'
        'right';
    }
  }
}

.country-loader {
  height: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
}
