@import '../../utilities/theme.scss';

button.primary-button {
  background: $primary;
  border: none;
  border-radius: 19px;
  height: 38px;
  padding: 0 16px;
  color: #fff;
  font-size: 15px !important;
  display: flex;
  align-items: center;

  > div {
    width: 32px;
    margin-right: 8px;
  }

  img {
    height: 24px;
  }

  &:disabled {
    opacity: 0.5;
  }
}

button.primary-button-center {
  justify-content: center;
}

button.primary-button-active {
}
