.editorial-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 36px;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;

  @media (max-width: 1040px) {
    & {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
    }

    .editorial-ad {
      display: none;
    }
  }

  @media (max-width: 768px) {
    & {
      grid-template-columns: 1fr;
    }
  }

  @media (min-width: 1040px) {
    & {
      grid-template-areas:
        '. . ad'
        '. . ad';
    }
  }

  h3 {
    font-size: 24px;
    hyphens: none;
    margin-bottom: 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  @media (min-width: 1040px) {
    > :nth-child(2) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    > :nth-child(5) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 3;
      grid-row-end: 5;
    }

    > :nth-child(2),
    > :nth-child(5) {
      h3 {
        font-size: 48px;
        line-height: 58px;
      }

      h3 {
        @media (max-width: 1260px) {
          & {
            font-size: 36px;
            line-height: 40px;
          }
        }
      }
    }
  }

  .editorial-ad {
    grid-area: ad;
  }
}
