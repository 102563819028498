.map-filters {
  grid-column-start: 1;
  grid-column-end: 4;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #adadad;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    & {
      flex-direction: column;
      margin-top: 16px;
    }
  }

  .map-filters-status {
    display: flex;
    @media (max-width: 768px) {
      & {
        flex-direction: column;
      }
    }

    .map-filter-departure-country {
      margin-right: 8px;

      @media (max-width: 768px) {
        margin-bottom: 4px;
        margin-right: 0px;
        max-width: 100%;
      }

      > div > div:first-of-type {
        border: 1px solid #adadad;
        border-radius: 19px;
      }
    }

    button {
      margin-right: 8px;

      @media (max-width: 768px) {
        & {
          display: none;
        }
      }
    }
  }

  .map-filters-display {
    display: flex;

    button {
      &:nth-of-type(1) {
        margin-right: 4px;
      }

      &:nth-of-type(2) {
        margin-left: 4px;
      }

      @media (max-width: 768px) {
        & {
          width: 100%;
          margin-top: 16px;
        }
      }
    }
  }

  .map-filters-country-option {
    display: flex;
    align-items: center;
    color: #000;

    img {
      width: 16px;
      height: auto;
      margin-right: 12px;
    }
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    position: relative;
    padding-left: 0;
  }

  // All these 3 classes come with ugly "table" display...
  .Select-control,
  .Select-clear-zone,
  .Select-arrow-zone {
    display: inherit;
  }

  // here is the trick: we display the wrapper as flex in order to make it fit in height
  // we flip positions of .Select-value and .Select-input using row-reverse in order to have a nice input to the left and no to the right
  .select-custom-class .Select-multi-value-wrapper {
    display: flex;
    flex-direction: row-reverse;
  }

  // we put our controls back to a better center position
  .Select-clear-zone {
    position: absolute;
    top: 8px;
    right: 20px;
  }

  .Select-arrow-zone {
    position: absolute;
    top: 8px;
    right: 0px;
  }
}
