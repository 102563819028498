.article-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fefefe;
  cursor: pointer;
  padding-bottom: 14px;
  -webkit-line-clamp: 2;
  min-height: 400px;

  .article-card-image-container {
    overflow: hidden;
  }

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .article-card-read-more {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    font-size: 36px;
    color: #aaa;
    margin-right: 10px;
  }

  &:hover {
    img {
      transform: scale(1.03);
    }

    > span {
      color: #333;
    }
  }

  img {
    width: 100%;
    margin-bottom: -4px;
    transition: all 0.34s ease-in-out;
  }

  h3 {
    margin: 8px;
    color: #000;
    margin: 16px 24px 36px 24px;
  }
}
