.main-header-admin {
  background-color: #222;
  height: 36px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  a {
    color: #fff;
  }
}

header.main-header {
  display: flex;
  justify-content: center;
  height: 100px;
  background-color: #111;
  align-items: center;
  font-size: 18px;

  .main-header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1340px;
    height: 100px;
    align-items: center;
    padding: 0 36px;
    font-size: 18px;
  }

  a {
    color: #fff;
  }

  input {
    padding: 8px 12px;
    border-radius: 12px;
    box-shadow: none;
    border: none;
    &:focus {
      outline: none;
    }
  }

  img.main-header-logo {
    height: 36px;
    width: auto;
  }

  .main-header-sign-in {
    display: flex;

    input {
      width: 200px;
      margin: 0px 0px 0px -12px;
      height: 40px;
      animation-name: animate-width;
      animation-duration: 0.5s;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 40px;
      width: 60px;
      font-size: 15px;

      img {
        width: 150%;
        margin-left: -10px;
      }
    }
  }

  @keyframes animate-width {
    from {
      width: 68px;
    }
    to {
      width: 220px;
    }
  }

  .main-header-right-desktop {
    display: flex;
    align-items: center;

    .main-header-user-container {
      color: #fff;
      font-size: 15px;

      .sign-out-icon {
        cursor: pointer;
      }
    }

    input {
      font-size: 15px;
    }

    .main-header-sign-in-container button {
      background: none;
      padding: 0;
      display: flex;
      align-items: center;
      margin-left: 24px;
      margin-right: 24px;
      color: #fff;
      white-space: nowrap;

      img {
        height: 20px;
        margin-right: 6px;
      }
    }

    .main-header-signing-in {
      color: #fff;
    }

    .main-header-language {
      margin-right: 24px;
      font-size: 14px;
      white-space: nowrap;
      color: #777;

      span {
        cursor: pointer;
        color: #fff;
      }
    }

    .main-header-choose-country {
      // width: 240px;
      margin-left: 36px;
      font-size: 15px;
    }

    & > div :last-child > div {
      max-height: 600px;
      background: #111;
    }

    .main-header-choose-country-placeholder {
      display: flex;
      align-items: center;

      img {
        height: 24px;
        margin-right: 10px;
      }
    }

    .main-header-country-option {
      display: flex;
      align-items: center;
      color: #fff;

      img {
        width: 30px;
        height: auto;
        margin-right: 12px;
      }
    }
  }

  .main-header-right-mobile {
    display: none;
  }

  .main-header-burger {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 36px;
    margin-left: 32px;
    cursor: pointer;

    span {
      width: 100%;
      height: 4px;
      margin: 4px 0;
      border-radius: 3px;
      background: #fff;
    }
  }

  @media (max-width: 1040px) {
    .main-header-right-desktop {
      display: none;
    }

    .main-header-right-mobile {
      display: flex;
    }
  }
}
