.byline {
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 0 24px;
  margin: 16px 0;
  font-size: 14px;

  img {
    width: 72px;
    height: auto;
    border-radius: 40px;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      margin-bottom: 4px;
      padding: 2px;
      padding-left: 0px;
      border-bottom: 1px solid #ddd;
    }

    p {
      color: #868686;
      font-size: 15px;
    }
  }
}
