@import '../../utilities/theme.scss';

.map-information {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;

  @media (max-width: 768px) {
    & {
      grid-template-columns: 1fr;
    }

    .map-information-disclaimer {
      margin-top: 16px;
      font-size: 13px;
      line-height: 16px;
      padding: 4px;
      border: 1px solid #999;
      background: #eee;
      border-radius: 4px;
    }
  }

  .map-information-numbers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    div {
      text-align: center;
    }

    .map-information-open {
      h1 {
        color: $green;
      }
    }

    .map-information-restricted {
      h1 {
        color: $orange;
      }
    }

    .map-information-closed {
      h1 {
        color: $red;
      }
    }
  }

  .map-information-disclaimer {
    display: flex;
    align-items: center;
  }
}
