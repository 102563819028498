.article-image {
  margin: 12px 0;

  img {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    border-radius: 4px;
  }

  p {
    margin-top: -8px;
    padding: 8px;
    padding-top: 10px;
    background: #fff;
  }
}
