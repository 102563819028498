.map-legend {
  width: 100%;
  padding-left: 1px;
  padding-right: 1px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .map-legend-colors {
    display: flex;

    .map-legend-info {
      display: flex;
      align-items: center;
      margin-right: 12px;

      > div {
        width: 16px;
        height: 16px;
        border-radius: 4px;
        margin-right: 6px;
      }
    }
  }

  .map-legend-disclaimer {
    font-size: 12px;
    line-height: 14px;
    font-style: italic;
    text-align: right;
  }

  @media (max-width: 768px) {
    & {
      grid-template-columns: 1fr;
      font-size: 12px;
    }

    .map-legend-disclaimer {
      margin-top: 8px;
      text-align: left;
    }
  }
}

.map-legend-info-open {
  background: #2ea333;
}

.map-legend-info-restricted {
  background: #f6a312;
}

.map-legend-info-closed {
  background: #dd1111;
}

.map-legend-info-unknown {
  background: #616161;
}
