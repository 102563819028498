.updates-outer-container {
  .updates-container {
    box-shadow: 0px 0px 16px fborderrgba(0, 0, 0, 0.05);
    background: #fefefe;

    @media (max-width: 1040px) {
      margin-top: 30px;
    }

    .updates-header {
      background: #d22;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: InterBold;
      font-size: 24px;
      height: 62px;

      img {
        height: 28px;
        margin-right: 12px;
      }
    }

    .updates-content {
      padding: 42px;
      padding-top: 20px;
      padding-bottom: 10px;
      font-size: 18px;
      line-height: 22px;

      .updates-item {
        margin-bottom: 24px;
      }

      .updates-item-date {
        color: #8a8a8a;
        margin-bottom: 8px;
        border-top: 1px solid #ddd;
        padding-top: 8px;
        font-size: 15px;
        letter-spacing: -0.02em;
      }

      .updates-item-title {
        font-family: InterMedium;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        img {
          width: 34px;
          margin-right: 6px;
        }
      }

      .updates-item-text {
        font-size: 15px;
      }

      .updates-item-read-more {
        text-align: right;
        color: #8a8a8a;
        font-size: 13.5px;
        margin-top: 8px;
      }
    }
  }
}
