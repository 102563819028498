.sign-up-content-container {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 30px;
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .sign-up-creating-account {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  .sign-up-premium {
    display: flex;
    background: #111;
    color: #fff;
    flex-direction: column;
    padding: 32px;
    padding-left: 50px;
    padding-right: 50px;

    > p {
      margin-top: 16px;
      line-height: 160%;
    }

    .sign-up-premium-checklist {
      display: flex;
      flex-direction: column;

      > div {
        display: grid;
        grid-template-columns: 1fr 64px 64px;
      }

      .sign-up-premium-checklist-header {
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex;
        justify-content: flex-end !important;
        white-space: nowrap;
      }

      .sign-up-premium-checklist-row {
        border-bottom: 1px dashed #555;
        margin-bottom: 10px;
        padding-bottom: 10px;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        > div:first-child {
          justify-content: flex-start;
        }

        &:last-child {
          border: none;
        }

        img {
          width: 24px;
          margin-bottom: 4px;
        }
      }
    }
  }
  .sign-up-form {
    display: flex;
    flex-direction: column;

    form > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;
    }

    label {
      font-size: 16px;
      margin-left: 4px;
      margin-bottom: 6px;
    }

    input {
      box-sizing: border-box;
      height: 40px;
      font-size: 15px;

      padding: 10px 12px;

      border: 1px solid transparent;
      border-radius: 4px;
      background-color: white;

      box-shadow: 0 1px 3px 0 #e6ebf1;
      -webkit-transition: box-shadow 150ms ease;
      transition: box-shadow 150ms ease;

      :focus {
        border: none;
        box-shadow: 0 1px 3px 0 #cfd7df;
      }
    }

    .sign-up-form-details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      > div {
        display: flex;
        flex-direction: column;

        width: 45%;
      }
    }

    button {
      float: right;
    }

    img {
      width: 200px;
      align-self: flex-end;
      margin-top: 16px;
    }
  }
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
