.primary-container {
  display: flex;
  flex-direction: column;
  hyphens: auto;

  .primary-hero {
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    min-height: 212px;

    .primary-hero-title-background {
      width: 100%;
      display: flex;
      justify-content: center;
      background: linear-gradient(rgba(#000, 0) 60%, #000 140%);
    }

    .primary-hero-title {
      display: flex;
      align-self: flex-end;
      width: 100%;

      max-width: 1260px;

      h1 {
        color: #fff;
        margin-left: 24px;
        margin-bottom: 12px;
      }
    }
  }

  .primary-content-container {
    align-self: center;
    width: 100%;
    max-width: 1260px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .primary-content {
    gap: 36px;
    margin-top: 36px;
    margin-bottom: 36px;
  }
}
