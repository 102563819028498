.profile-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  width: 100%;

  @media (max-width: 1040px) {
    grid-template-columns: 1fr;
    > div {
      margin-bottom: 30px;
    }
  }

  .profile-cancel-signout {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }

  .profile-subscriptions {
    display: flex;
    flex-direction: column;

    h4 {
      margin-top: 8px;
      margin-bottom: 0px;
    }

    > div {
      display: flex;
      flex-direction: column;
    }
  }

  button {
    margin-top: 16px;
    width: 100%;
  }
}
