@import '../utilities/theme.scss';

.header-divider {
  background: $primary;
  height: 60px;
  padding-left: 16px;
  display: flex;
  align-items: center;

  @media (max-width: 1040px) {
    & {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  h3 {
    color: #fff;
  }
}
