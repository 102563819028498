.hero-container {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 36px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.08);
  min-height: 224px;

  @media (max-width: 768px) {
    & {
      margin-bottom: 0;
    }
  }

  .hero-content {
    width: 76%;
    margin: 0 auto;
    max-width: 1260px;
    display: grid;
    grid-template-rows: 2fr 1fr;
    text-align: center;
    color: #fff;

    @media (min-width: 768px) {
      & {
        grid-template-rows: 4fr 3fr;
      }
    }

    h1 {
      display: flex;
      align-items: center;
    }

    .hero-search-destination {
      width: 80%;
      margin: 0 auto;
      max-width: 800px;
      display: flex;
      align-items: center;
    }

    @media (max-width: 768px) {
      & {
        width: 95%;
      }

      .hero-search-destination {
        width: 100%;
        max-width: 800px;
        align-self: center;
      }

      h1 {
        font-size: 20px;
      }
    }
  }

  .hero-footer {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.4) 80%);
    align-self: flex-end;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    color: #eee;
    font-size: 13px;
    padding: 24px 6px 6px 0px;

    a {
      color: #eee;
    }
  }
}
