.article-featured-image {
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  height: 280px;
}

.article-content-container {
  display: grid;
  grid-template-columns: 1fr 320px;
  gap: 90px;
  margin-top: 50px;
  margin-bottom: 30px;
  align-self: center;
  max-width: 1260px;

  img {
    width: 100%;
  }

  .article-ad-mobile {
    max-width: 500px;
    margin: 0 auto;
  }

  @media (max-width: 1040px) {
    & {
      grid-template-columns: 1fr;
    }

    .article-ad-desktop {
      display: none;
    }
  }

  @media (min-width: 1040px) {
    .article-ad-mobile {
      display: none;
    }
  }
}

.article-content {
  width: 100%;

  h2 {
    margin-bottom: 20px;
  }

  h4 {
    margin-bottom: 28px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }
}
