.sign-up-complete-container {
  h1 {
    margin-bottom: 24px;
  }

  .sign-up-complete-content {
    :last-child {
      font-style: italic;
    }
  }
}
