$primary: #f6a312;
// $primary: #f7a80d; // old

$warning: #ff0202;

$green: #2fa233;
$orange: #f6a213;
$red: #ff0202;

$background: #e5e5e5;
