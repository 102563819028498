.country-restrictions-list {
  margin-top: 16px;

  a {
    color: #111;
  }

  .country-restrictions-list-columns {
    margin: 30px 0;
    column-width: 300px;
    column-count: 4;

    p {
      height: 20px;
    }

    h5 {
      height: 20px;
    }

    > div {
      display: flex;
      align-items: center;
    }

    > div > div:first-child {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      margin-right: 6px;
    }
  }

  .country-restrictions-list-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #555;
    font-style: italic;
    height: 320px;
  }
}
