.about-container {
  img {
    width: 50%;

    &.about-us-right {
      padding: 16px 0 16px 32px;
      float: right;
    }

    &.about-us-left {
      padding: 0px 32px 16px 0;
      float: left;
    }

    @media (max-width: 1040px) {
      & {
        width: 70%;
      }
    }

    @media (max-width: 768px) {
      & {
        width: 100%;
        padding: 16px 0px !important;
      }
    }
  }
}
