.secondary-button {
  border: none;
  border-radius: 19px;
  height: 38px;
  padding: 0 16px;
  font-size: 15px !important;
  border: 1px solid #adadad;
  display: flex;
  align-items: center;

  &.inactive {
    background: #fff;
    color: #333;

    &:hover {
      color: rgba(0, 0, 0, 0.8);

      img {
        opacity: 0.9;
      }
    }
  }

  &.active {
    background: #111;
    color: #fff;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  img {
    width: auto;
    height: 20px;
    margin-right: 8px;
    margin-left: -4px;
  }
}
