.top-menu {
  display: flex;
  justify-content: center;
  background: #efefef;

  .top-menu-mobile {
    position: relative;
    background-color: #f7a80d;
    transition: height ease-in-out 0.4s;
    display: grid;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    grid-template-rows: 1fr 60px;
    overflow: hidden;

    &.open {
      height: 240px;
    }

    &.closed {
      height: 0px;
    }

    .arrow {
      position: absolute;
      right: 30px;
      width: 26px;
      height: 26px;
      background: #f7a80d;
      display: none;
      transition: transform ease-in-out 0.4s;

      &.open {
        display: inline;
        transform: scale(1, 1) rotate(45deg);
      }

      &.closed {
        display: inline;
        transform: scale(0, 0) rotate(45deg);
      }
    }

    .top-menu-content {
      display: grid;
      flex-direction: column;
      grid-template-rows: repeat(1fr);
      margin: 16px 36px 0px 36px;

      a {
        margin-bottom: 6px;
      }
    }

    .top-menu-footer {
      display: flex;
      flex-direction: column;
      justify-content: space;
      margin: 0 36px;
      grid-template-areas: 'settings';
      padding-top: 12px;
      border-top: 1px solid #fff;

      form {
        display: flex;
        align-items: center;
        margin-top: -12px;

        input {
          height: 36px;
          padding-left: 16px;
          border: 0;
          border-radius: 18px;

          &:focus {
            outline: none;
          }
        }

        button {
          border-radius: 0;
          border-top-right-radius: 18px;
          border-bottom-right-radius: 18px;
          background: #111;
        }
      }

      .top-menu-signing-in {
        display: flex;
        align-items: center;
        color: #fff;
        margin-top: -10px;
      }

      .top-menu-sign-in-container {
        width: 100%;
        display: flex;

        button {
          margin-left: -16px;
          display: flex;
          align-items: center;

          img {
            width: 24px;
            margin-right: 8px;
          }
        }
      }
      .top-menu-settings {
        grid-area: settings;
        padding-top: 16px;
        border-top: 1px solid #fff;

        a {
          display: flex;
          align-items: center;
        }
        img {
          width: 20px;
          margin-right: 8px;
        }
      }
    }

    .top-menu-choose-language {
      color: #fff;
      position: absolute;
      bottom: 16px;
      right: 24px;
    }

    a {
      color: #fff;
    }
  }

  @media (min-width: 1040px) {
    .top-menu-mobile {
      display: none;
    }
  }

  .top-menu-desktop {
    height: 56px;
    background: #efefef;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    max-width: 1372px;

    a {
      color: #000;
      font-size: 15px;
    }

    .top-menu-active {
      font-family: InterBold;
      // letter-spacing: -1px;
    }
  }

  @media (max-width: 1040px) {
    .top-menu-desktop {
      display: none;
    }
  }
}
