footer {
  height: 300px;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: #181818;
  color: #fff;

  img {
    height: 60%;
    margin-left: 16px;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
