.country-info {
  background: #4b4b4b;
  border-radius: 20px;
  color: #fff;
  padding: 20px 16px 16px 0;
  width: 100%;
  height: 180px;
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr;

  a {
    color: #fff;
  }

  @media (max-width: 768px) {
    & {
      grid-template-columns: 1fr;
      height: auto;
    }
  }

  .country-info-column-divider {
    background: #646464;
    width: 1px;
    height: 92%;
    margin: 6px auto 0 auto;

    @media (max-width: 768px) {
      & {
        height: 1px;
        width: 92%;
        display: inline;
      }
    }
  }

  .country-info-column {
    display: flex;
    margin: 0 16px 8px 16px;
    justify-content: space-between;
    align-items: center;

    .country-info-sub-column {
    }

    img {
      width: auto;
      height: 48px;
      margin-right: 16px;
    }

    .country-info-covid {
      display: flex;
      width: 100%;
      justify-content: space-around;
      text-align: center;

      @media (max-width: 960px) {
        & {
          flex-direction: column;
        }
      }

      h2 {
        text-align: center;
      }
    }

    .country-info-covid-info {
      width: 100%;
      text-align: center;
      font-size: 13px;
      margin-top: 12px;
    }

    .country-info-covid-tooltip {
      background: rgba(0, 0, 0, 0.2);
      text-shadow: 0px 0px 3px #333;
      padding: 8px;
      border-radius: 6px;
    }

    div.country-info-risk-score {
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;

      > div {
        text-align: center;
      }

      span.risk-score {
        font-size: 24px;
      }

      p {
        font-style: italic;
        font-size: 14px;
      }
    }
  }
}
