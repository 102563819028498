.sidebar {
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  > h3 {
    background: #111;
    color: #fff;
    padding: 8px;
    font-size: 18px;
    width: 100%;
  }

  .sidebar-content {
    padding: 24px;

    h3 {
      line-height: 1.1;
      font-size: 22px;
      margin-top: 18px;
      margin-bottom: 18px;
    }

    img {
      width: 70%;
      height: auto;
    }

    p {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .sidebar-divider {
    width: 90%;
    height: 1px;
    background: #999;
  }
}
