.premium-banner-container {
  background: #f7a80d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: Inter;
  color: #fff;
  padding: 16px 72px 18px 24px;
  height: 58px;

  @media (max-width: 1040px) {
    & {
      height: auto;
    }
  }

  button {
    background: none;
  }

  :hover {
    color: #fff;
  }

  a {
    color: #fff;
    margin-right: 24px;
  }

  img {
    height: 20px;
    margin-right: 12px;
    margin-bottom: -2px;
  }

  .close {
    position: absolute;
    right: 32px;
    width: 32px;
    height: 32px;
    opacity: 1;
  }
  .close:hover {
    opacity: 0.8;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #fff;
  }
  .close:before {
    margin-top: -16px;
    transform: rotate(45deg);
  }
  .close:after {
    margin-top: -16px;
    transform: rotate(-45deg);
  }
}
