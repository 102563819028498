@import '../utilities/theme.scss';

.select-destination-country-container {
  width: 100%;
  margin-top: -28px;
  color: #000;

  .select-destination-country {
    font-size: 20px;
    background-color: #fff;
    background-image: url(/globe_primary.png);
    border-radius: 31px;
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 62px;
    padding-right: 31px;
    background-position-x: 0;
    height: 62px;
    margin: 62px;
    margin-bottom: 38px;

    @media (max-width: 768px) {
      & {
        font-size: 16px;
      }
    }

    & > div :last-child > div {
      max-height: 600px;
    }

    .select-destination-country-option {
      display: flex;
      align-items: center;

      img {
        width: 36px;
        margin-right: 12px;
      }
    }

    .select-destination-country-placeholder {
      display: flex;
      align-items: center;

      img {
        height: 48px;
        margin-left: -48px;
        z-index: 10000;
        margin-right: 24px;
      }
    }

    div {
      border: none;
    }
  }
}

@media (max-width: 1040px) {
  .select-destination-country-container {
    width: 90%;

    .select-destination-country {
      margin: 12px;
      margin-left: 74px;
      margin-top: 56px;
    }
  }
}

@media (max-width: 768px) {
  .select-destination-country-container {
    width: 100%;

    .select-destination-country {
      margin: 12px;
    }
  }
}
