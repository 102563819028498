@import '../theme.scss';

label.checkbox {
  cursor: pointer;

  &:hover {
    background: #d4d5d6;
  }
}

label.checkbox > input[type='checkbox'] {
  display: none;
}

label.checkbox > input[type='checkbox'] + *::before {
  content: '';
  display: inline-block;
  vertical-align: bottom;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.3rem;
  border-radius: 10%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}

label.checkbox > input[type='checkbox']:checked + * {
  color: #000;
}

label.checkbox > input[type='checkbox']:checked + *::before {
  content: '✓';
  color: white;
  font-size: 20px;
  text-align: center;
  background: $primary;
  vertical-align: top;
  border-color: $primary;
}

label.checkbox > input[type='checkbox'] + * {
  display: inline-block;
  padding: 0.5rem 1rem;
}
