.travel-from {
  .home-content-map {
    margin-top: 16px;
  }

  .travel-from-bottom {
    display: flex;
    justify-content: space-between;
  }
}
