.country-header-container {
  display: flex;
  flex-direction: column;

  .country-header-hero {
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    height: 60px;

    @media (max-width: 768px) {
      & {
        height: 72px;
      }
    }

    .country-header-hero-title-background {
      width: 100%;
      display: flex;
      justify-content: center;

      .country-header-hero-title {
        max-width: 1260px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 768px) {
          & {
            flex-direction: column;

            > div {
              width: 100%;
            }
          }
        }

        .country-header-hero-left {
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          img.country-header-hero-back-button {
            width: 40px;
            height: auto;
            margin-top: 37px;

            @media (max-width: 768px) {
              & {
                margin-top: 24px;
              }
            }
          }

          h1 {
            font-size: 52px;
            margin-left: 24px;

            @media (max-width: 768px) {
              & {
                font-size: 28px;
              }
            }
          }
        }

        .country-header-hero-right {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;

          img {
            margin-left: 28px;
          }

          .country-header-hero-status {
            width: 160px;

            h3 {
              white-space: nowrap;
              margin-bottom: -6px;
            }

            span {
              color: #333;
            }
          }
          img.country-header-open-status {
            width: 56px;
          }

          .country-header-hero-notifications {
            width: 36px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .country-header-content-container {
    align-self: center;
    max-width: 126px;
  }

  .country-header-content {
    gap: 52px;
    margin-top: 52px;
    margin-bottom: 52px;
  }
}
