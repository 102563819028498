@import './components/utilities/theme.scss';

@font-face {
  font-family: Inter;
  src: url(/inter-regular.ttf);
}

@font-face {
  font-family: InterBold;
  src: url(/inter-bold.ttf);
}

@font-face {
  font-family: InterSemiBold;
  src: url(/inter-semibold.ttf);
}

@font-face {
  font-family: InterMedium;
  src: url(/inter-medium.ttf);
}

@font-face {
  font-family: InterLight;
  src: url(/inter-light.ttf);
}

@font-face {
  font-family: InterThin;
  src: url(/inter-thin.ttf);
}

* {
  -webkit-font-smoothing: antialiased;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.3px;
  font-size: 15px;
  background: #f4f4f4;
}

#root {
  height: 100%;
}

.app-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-content {
  flex: 1 0 auto;
}

h1 {
  font-size: 52px;
  margin-bottom: 0px;
  letter-spacing: -0.04em;
}

@media (max-width: 1260px) {
  h1 {
    font-size: 48px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 36px;
  }
}

h2 {
  font-size: 36px;
  margin-bottom: 0px;
  letter-spacing: -0.04em;
}

@media (max-width: 768px) {
  h2 {
    font-size: 28px;
  }
}

h3 {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.04em;
}

h4 {
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.03em;
}

h5 {
  font-size: 20px;
  letter-spacing: -0.04em;
}

h6 {
  font-size: 16px;
}

button {
  font-family: Inter !important;
  cursor: pointer;
  border: none;
  background: #f7a80d;
  border-radius: 19px;
  height: 38px;
  padding: 0 16px;
  color: #fff;
  font-size: 15px !important;

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.9;
  }
}

h1,
h2,
h3,
h5,
.header {
  font-family: InterBold !important;
}

span,
p {
  font-family: Inter !important;
  letter-spacing: -0.02em;
}

h4,
h6 {
  font-family: Inter !important;
  font-weight: unset;
}

h4 {
  font-family: InterMedium !important;
  font-weight: unset;
}

a:hover {
  color: $primary;
  cursor: pointer;
}
